import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Visits from './components/Visits';
import { ROLE } from '../../../configs/exports';
import BreakingAdminnews from './components/breakingNews';
// import ApplicantDocuSummary from './components/ApplicantDocuSummary';
// import PassportDoc from './components/Passport_Doc';
// import Medical1 from './components/Medical1';
import * as moment from 'moment'
import { FormControl } from 'react-bootstrap'
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'

function DefaultDash({ t, rtl }) {
  let now = new Date()
  let mstart = moment(
    new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
  )

  let mend = moment(mstart).add(1, 'days').subtract(1, 'seconds')
  const [start, setStart] = useState(mstart);
  const [end, setEnd] = useState(mend);
  const [valuedate, setValues] = useState('Today');
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const handleRefreshChild = (refresh) => {
  };



  useEffect(() => {
    triggerChildRefresh(end)

  }, [start, end])

  const triggerChildRefresh = (prevState) => {

    setRefreshTrigger(prevState);

  };

  const getDataUpdate = () => {
    // let queryString = ("filter_value=&page_number=0&page_size=10&sort_order=false")
    setStart(mstart)
    setEnd(mend)
    setValues("Today")
  }

  const applyCallback = (startDate, endDate) => {
    localStorage.setItem('STARTDATE', startDate)
    localStorage.setItem('ENDDATE', endDate)
    let Sdate1 = moment(startDate).format('DD MMM, YYYY')
    let Edate2 = moment(endDate).format('DD MMM, YYYY')

    setStart(startDate)
    setEnd(endDate)
    setValues(Sdate1 + ' ' + Edate2)

    // this.setState({
    //   valuedate: Sdate1 + ' ' + Edate2,
    //   startDate: Sdate1,
    //   isPageLoad: true,
    //   endDate: Edate2
    // })

    // this.getData(
    //   '',
    //   moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
    //   moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
    //   channel
    // )
  }

  let ranges = {
    'Today Only': [moment(start), moment(end)],
    'Yesterday Only': [
      moment(start).subtract(1, 'days'),
      moment(end).subtract(1, 'days')
    ],
    '3 Days': [
      moment(start).subtract(3, 'days'),
      moment(end)
    ],
    '5 Days': [
      moment(start).subtract(5, 'days'),
      moment(end)
    ],
    '1 Week': [
      moment(start).subtract(7, 'days'),
      moment(end)
    ],
    '2 Weeks': [
      moment(start).subtract(14, 'days'),
      moment(end)
    ],
    '1 Month': [
      moment(start).subtract(1, 'months'),
      moment(end)
    ],
    '1 Year': [
      moment(start).subtract(1, 'years'),
      moment(end)
    ]
  }
  let local = {
    format: 'DD-MM-YYYY',
    sundayFirst: false
  }

  return (
    <Col className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Dashboard</h3>
          <div className='row'>
            <div className='col-md-8'>
              <div className='col-md-8'>
                <div className='row'>
                  <div className='col-6'>
                    <DateTimeRangeContainer
                      ranges={ranges}
                      start={start}
                      end={end}
                      local={local}
                      applyCallback={applyCallback}
                    >
                      <FormControl
                        id='formControlsTextB'
                        type='text'
                        value={valuedate}
                        label='Text'
                        placeholder='Filter by Date'
                      />
                    </DateTimeRangeContainer>
                  </div>
                  <div className='col-6 float-left'>
                    <button
                      className=' float-left btn btn-primary btn-sm'
                      onClick={e => {

                        getDataUpdate()

                      }}
                    >
                      Clear
                    </button>
                    {/* <input id="input" type="text" readOnly /> */}
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-4'></div>
          </div>
          <br />

          <br />
          <br />
        </Col>


      </Row>
      <Row >
        <>
          {start ?
            <BreakingAdminnews refresh={refreshTrigger} startdate={moment(start).format('YYYY-MM-DD HH:mm:ss')} enddate={moment(end).format('YYYY-MM-DD HH:mm:ss')} />
            : null}
        </>


      </Row>
      <br />
    </Col >
  )
}

DefaultDash.propTypes = {
  t: PropTypes.func.isRequired,
};

export default compose(withTranslation('common'), connect(state => ({
  rtl: state.rtl,
})))(DefaultDash);
